// 车辆导入 人员导入 socket处理
import React from 'react';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import '../style/entry-socket.less';
@withRouter
@Decorator.businessProvider('tab')
@observer
class VehicleEntrySocket extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    data: {}
  };
  componentDidMount() {
    SocketEmitter.on('importVillage', this.importVillage);
  }
  componentWillUnmount() {
    SocketEmitter.off('importVillage', this.importVillage);
  }

  importVillage = (res = {}) => {
    const { message = {} } = res;
    const { totalCount = 0, wrongInfoCount = 0, noPicCount = 0, successCount = 0, otherCount = 0, failCount = 0, picWithoutFeatureCount = 0, type = 'PEOPLE' } = message;
    notification.config({
      getContainer: () => document.querySelector('#root'),
      duration: 50
    });
    this.setState({ data: message });
    const isSuccess = res.code === 200 ? true : false;
    const content = res.message && (
      <div className="community-upload-modal">
        <div className="title">
          <span className="value">导入成功：</span>
          <div className="info">{type === 'PEOPLE' ? `${successCount}人` : `${totalCount - wrongInfoCount}车`}</div>
        </div>
        <div className="title">
          <span className="value">导入失败：</span>
          <div className="info">{type === 'PEOPLE' ? `${failCount}人` : `${wrongInfoCount}车`}</div>
        </div>
        {type === 'PEOPLE' && (
          <div className="title">
            <span className="value">失败原因：</span>
            <div className="value-box">
              <p className="box-title">
                <span className="box-span">照片无特征值 </span> {picWithoutFeatureCount || 0}人
              </p>
              <p className="box-title">
                <span className="box-span">暂无照片 </span> {noPicCount || 0}人
              </p>
              <p className="box-title">
                <span className="box-span">信息填写错误 </span> {wrongInfoCount || 0}人
              </p>
              <p className="box-title">
                <span className="box-span">其他 </span> {otherCount || 0}人
              </p>
            </div>
          </div>
        )}
      </div>
    );
    const btn = (
      <div>
        <span style={{ cursor: 'pointer' }} className="ok" onClick={() => this.goLibPage()}>
          进入小区{type === 'PEOPLE' ? '人员' : '车辆'}导入界面
        </span>
      </div>
    );

    notification[isSuccess ? 'success' : 'error']({
      className: 'vehicle-entry-item',
      placement: 'buttomRight',
      message: `${type === 'PEOPLE' ? '人员' : '车辆'}导入`,
      description: content,
      btn,
      key: 'vehicleEntrySocket'
    });
  };

  // 跳转页面
  goLibPage = () => {
    const { data = {} } = this.state;
    const { tab, location } = this.props;
    const { type } = data;
    const pageType = type === 'PEOPLE' ? true : false;
    notification.destroy();
    tab.goPage({
      location,
      moduleName: pageType ? 'peopleEntry' : 'vehicleEntry',
      data: {
        id: data.villageId,
        villageName: encodeURI(data.villageName)
      }
    });
  };

  render() {
    return null;
  }
}

export default VehicleEntrySocket;
