import React from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Layout } from "antd";
import ContentView from "./components/ContentView";
import SiderView from "./components/Sider";
import ImportLibSocket from "./components/ImportLibSocket.js";
import VehicleEntrySocket from "./components/VehicleEntrySocket";
import SubscribeDevice from "./components/SubscribeDevice";
import "./style/index.less";

const MediaLibrary = Loader.loadBusinessComponent('MediaLibrary')

@Decorator.errorBoundary
@withRouter
@inject("menu", "tab")
@observer
class LayoutView extends React.Component {
  constructor(props) {
    super(props);
    this.unlisten = null;
    this.timer = null;
    this.state = {
      mediaLibVisible: false // 视图库显隐状态
    };
  }

  componentWillMount() {
    const { history, menu, isRedirect } = this.props;
    this.unlisten = history.listen(location => {
      let url = location.pathname.substring(3, location.pathname.length);
      let currentModule = menu.getInfoByUrl(url);
      if (currentModule) {
        let menuItem = menu.findCurrentMenu(currentModule.code);
        menuItem && menu.setCurrentMenu(menuItem.name);
      }
    });
    isRedirect && this.computedRenderModule(this.props, true);
  }

  componentWillReceiveProps(nextProps) {
    this.computedRenderModule(nextProps);
  }
  componentWillUnmount() {
    this.unlisten();
  }
  computedRenderModule(props, flag) {
    const { history, match, location, tab, menu } = props;
    const { tabIndex } = match.params;
    const url = location.pathname.replace(`/${tabIndex}/`, "");
    const module = menu.getInfoByUrl(url);
    if (history.action === "POP" || flag) {
      tab.setPopStoreData({ tabIndex, location, module });
    }
  }

  // 显示、隐藏视图库
  showMediaLibrary = () => {
    this.setState({
      mediaLibVisible: true
    });
  };

  hideMediaLib = () => {
    this.setState({
      mediaLibVisible: false
    });
  };

  render() {
    const { mediaLibVisible } = this.state;
    return (
      <Layout className="content-container">
        <div id="insert-container" />
        <SiderView />
        <ContentView showMediaLibrary={this.showMediaLibrary} />
        {BaseStore.user.userInfo.userType !== '100701' && <MediaLibrary hideMediaLib={this.hideMediaLib} isVisible={mediaLibVisible} />}
        <ImportLibSocket />
        <VehicleEntrySocket />
        <SubscribeDevice />
      </Layout>
    );
  }
}
export default LayoutView;
