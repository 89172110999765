(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("mobxReact"), require("ReactRouterDOM"), require("_"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("AppComponent", ["React", "antd", "mobxReact", "ReactRouterDOM", "_", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["AppComponent"] = factory(require("React"), require("antd"), require("mobxReact"), require("ReactRouterDOM"), require("_"), require("ReactDOM"));
	else
		root["AppComponent"] = factory(root["React"], root["antd"], root["mobxReact"], root["ReactRouterDOM"], root["_"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 