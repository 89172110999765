import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Avatar, Popover } from 'antd'
import { observer, inject } from 'mobx-react'
import UserAction from './UserAction'
import AboutSystem from './AboutSystem'
import BroadComponent from './BroadComponent'
import _ from 'lodash'

const UserDefault = '/resource/image/user-default.svg'

const AuthComponent = Loader.loadBusinessComponent('AuthComponent')
const IconFont = Loader.loadBaseComponent('IconFont')

@withRouter
@inject('user', 'menu')
@observer
class RootHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      versionList: {},
      broadcastSrc: ''
    }
    this.init()
  }
  init() {
    Promise.all([Service.other.queryVersion(), Service.other.queryLink()]).then(res => {
      this.setState({
        broadcastSrc: res[1].broadcast,
        versionList: res[0]
      })
    })
  }
  render() {
    let { user, showMediaLibrary, menu } = this.props
    let { versionList = {}, broadcastSrc } = this.state
    let { realName, userType, userAvatarUrl } = user.userInfo
    const isAlarm = menu.getInfoByName('personnelRealAlarm')
    return (
      <React.Fragment>
        {userType !== '100701' && (
          <div className="user-item system-view" onClick={() => showMediaLibrary()}>
            <IconFont type="icon-S_Bar_Folder" theme="outlined" />
            <span className="media-library-btn">我的视图</span>
          </div>
        )}
        <AuthComponent actionName="broadcast">
          <Popover placement="bottom" overlayClassName={'version_card'} content={<BroadComponent downloadUrl={broadcastSrc.clientDownloadUrl} />}>
            <div className="user-item broadcast-item">
              <IconFont type="icon-S_Bar_Broadcast" theme="outlined" />
              <a href={broadcastSrc.path}>{broadcastSrc.name}</a>
            </div>
          </Popover>
        </AuthComponent>
        <AuthComponent actionName="systemHelper">
          <Popover
            getPopupContainer={() => document.getElementById('insert-container')}
            placement="bottom"
            overlayClassName={'version_card'}
            content={<AboutSystem versionList={versionList.about} />}
          >
            <a className="user-item system-about" target="_blank" href="/about">
              <IconFont type="icon-S_Bar_About" theme="outlined" />
              <span className="about-text">帮助中心</span>
            </a>
          </Popover>
        </AuthComponent>
        <Popover
          placement="bottomRight"
          content={<UserAction userInfo={user.userInfo} userAvatarUrl={userAvatarUrl} />}
          getPopupContainer={() => document.getElementById('insert-container')}
        >
          <div className="user-item user-info">
            <Avatar size={36} icon="user" src={userAvatarUrl} onError={() => user.updateUserAvatar(UserDefault)} />
            <span className="user-name" title={realName}>
              {realName}
            </span>
            <IconFont type="icon-S_Arrow_SmallDown" theme="outlined" />
          </div>
        </Popover>
        {isAlarm && <AlarmNumIcon />}
      </React.Fragment>
    )
  }
}

@inject('user', 'menu', 'tab')
class AlarmNumIcon extends React.Component {
  state = {
    alarmNum: 0
  }
  audioRef = React.createRef()
  timer = null
  componentDidMount() {
    SocketEmitter.on(SocketEmitter.eventName.alarmNum, this.notifyAlarmNum)
    SocketEmitter.on(SocketEmitter.eventName.alarm, this.handleAlarm)
    Service.statistics.countAlarmResultsByHandleType({ alarmTypes: ['1', '2', '4'] }).then(res => {
      this.setState({ alarmNum: res.data.unHandledCount })
    })
  }
  componentWillUnmount() {
    this.debounced && this.debounced.cancel()
    SocketEmitter.off(SocketEmitter.eventName.alarmNum, this.notifyAlarmNum)
    SocketEmitter.off(SocketEmitter.eventName.alarm, this.handleAlarm)
    this.audioRef = null
    this.timer = null
  }
  handleAlarm = () => {
    this.debounced && this.debounced.cancel()
    this.debounced = _.debounce(() => {
      this.audioRef.current && this.audioRef.current.play()
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.audioRef.current.pause()
      }, 3000)
    }, 500)
    this.debounced()
  }
  notifyAlarmNum = data => {
    const { user } = this.props
    if (user.userInfo.id == data.userId) {
      this.setState({ alarmNum: data.unHandleNum })
    }
  }
  jumpAlarm = () => {
    const { tab } = this.props
    tab.goPage({
      moduleName: 'personnelRealAlarm',
      location: window.ReactHistory.location
    })
  }
  render() {
    const { alarmNum } = this.state
    return (
      <div className="user-item system-alarm" onClick={this.jumpAlarm}>
        <audio src="/resource/media/alarm.mp3" ref={this.audioRef} autoplay={false} loop={false} />
        <IconFont type="icon-S_Photo_AlarmOpen" theme="outlined" />
        <span title={alarmNum}>{alarmNum > 999 ? `999+` : alarmNum}</span>
      </div>
    )
  }
}

export default RootHeader
