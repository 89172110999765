import React from "react";
import { inject } from "mobx-react";

@inject("device")
class SubscribeDevice extends React.Component {
  constructor() {
    super();
    this.deviceList = [];
    SocketEmitter.on(SocketEmitter.eventName.device, this.scribeAction);
    this.flag = true;
    this.state = {
      changeOptions: []
    };
  }
  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.device, this.scribeAction);
  }
  componentDidUpdate() {
    const { changeOptions } = this.state;
    if (changeOptions.length > 0) {
      SocketEmitter.emit(SocketEmitter.eventName.deviceUpdate, changeOptions);
      this.setState({ changeOptions: [] });
    }
  }
  scribeAction = data => {
    if (data) {
      this.deviceList.push(data);
    }

    if (!this.flag) {
      return;
    }
    this.flag = false;
    const { device } = this.props;
    const index = this.deviceList.length;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let list = this.deviceList.splice(0, index);
      if (list.length > 0) {
        device.updateDevices(list, arr => {
          this.flag = true;
          this.setState({ changeOptions: arr });
          console.debug("推送设备变化, 更新集合=>", list, ",等待更新 =>", this.deviceList);
        });
      }
      if (this.deviceList.length > 0) {
        this.flag = true;
        this.scribeAction();
      }
    }, 5 * 1000);
  };
  render() {
    return null;
  }
}

export default SubscribeDevice;
