import React from "react";
import ReactDOM from "react-dom";
import { inject, observer } from "mobx-react";
import { Tabs, Divider } from "antd";
import { withRouter } from "react-router-dom";
import TabComponent from "./TabComponent";

const TabPane = Tabs.TabPane;

const IconFont = Loader.loadBaseComponent("IconFont");

@withRouter
@inject("tab", "menu")
@observer
class TabContainer extends React.Component {
  constructor() {
    super();
    this.menuDom = null;
    this.state = {
      visibel: false,
      position: [-1000, -1000],
      tabIndex: 0
    };
  }

  componentDidMount() {
    this.menuDom = document.querySelector(".menu-tab").querySelector(".ant-tabs-nav-wrap");
    this.menuDom.addEventListener("contextmenu", this.contextMenuAction, false);
  }
  componentWillUnmount() {
    this.menuDom.removeEventListener("contextmenu", this.contextMenuAction, false);
    document.body.removeEventListener("click", this.clickHideContextMenu, false);
    this.menuDom = null;
  }
  contextMenuAction = event => {
    event.preventDefault();
    let current = event.path.find(v => {
      return !!v.className && v.className.toString().indexOf("ant-tabs-tab") > -1;
    });
    if (!current) {
      return;
    }
    let index = current.querySelector(".tab-index-item").dataset.index;
    this.setState(
      {
        visibel: true,
        tabIndex: +index,
        position: [event.pageX, event.pageY]
      },
      () => {
        document.body.addEventListener("click", this.clickHideContextMenu, false);
      }
    );
  };

  clickHideContextMenu = e => {
    const { visibel } = this.state;
    if (!visibel) {
      return;
    }
    let current = e.path.find(v => {
      return !!v.className && v.className.toString().indexOf("tab-context-menu-layout") > -1;
    });
    if (!current) {
      this.hideContextMenu();
      document.body.removeEventListener("click", this.clickHideContextMenu, false);
    }
  };
  hideContextMenu = () => {
    this.setState({ visibel: false });
  };

  goTab = activeKey => {
    const { tab } = this.props;
    tab.changeTab(activeKey);
  };
  closeTab = activeKey => {
    const { tab } = this.props;
    tab.deleteTab(activeKey);
  };
  componentWillUpdate(){
    console.time('render')
  }
  componentDidUpdate(){
    console.timeEnd('render')
  }
  render() {
    const { visibel, tabIndex, position } = this.state;
    const { tab, menu } = this.props;
    const { currentId, tabList } = tab;
    return (
      <>
        <Tabs
          className={`menu-tab ${tabList.length === 1 ? "hide-menu-tab" : ""}`}
          key="tabs"
          type={"editable-card"}
          hideAdd={true}
          animated={true}
          activeKey={currentId}
          onChange={this.goTab}
          onEdit={this.closeTab}
        >
          {tabList.map((pane, idx) => {
            const module = menu.getInfoByName(pane.moduleName);
            const { id, menuName, index, icon } = pane;
            const funcItem = menu.auth.func.find(v => v.name === pane.moduleName);
            return (
              <TabPane
                tab={
                  <span className="tab-index-item" data-index={idx} title={funcItem ? funcItem.title : menuName}>
                    {icon && <IconFont type={icon} />}
                    {funcItem ? funcItem.title || funcItem.menuName : menuName}
                  </span>
                }
                key={id}
              >
                <TabComponent key={`${index}-${id}`} module={module} tabIndex={index} storeId={id} />
              </TabPane>
            );
          })}
        </Tabs>
        <TabContextMenuView visibel={visibel} tabIndex={tabIndex} position={position} closeCallback={this.hideContextMenu} />
      </>
    );
  }
}

@inject("tab", "menu")
@observer
class TabContextMenuView extends React.Component {
  constructor() {
    super();
    this.ele = document.createElement("div");
  }
  componentDidMount() {
    document.body.appendChild(this.ele);
  }
  componentWillUnmount() {
    document.body.removeChild(this.ele);
    this.ele = null;
  }

  closeCurrentTab = e => {
    Utils.stopPropagation(e);
    const { closeCallback, tab, tabIndex } = this.props;
    const tabItem = tab.tabList[tabIndex];
    tab.deleteTab(tabItem.id);
    closeCallback();
  };
  closeOtherTab = e => {
    Utils.stopPropagation(e);
    const { closeCallback, tab, tabIndex } = this.props;
    tab.closeOtherTab({ excludeIds: [tab.tabList[tabIndex].id], newCurrentId: tab.tabList[tabIndex].id });
    closeCallback();
  };
  closeRightTab = e => {
    Utils.stopPropagation(e);
    const { closeCallback, tab, tabIndex } = this.props;
    const startIndex = tab.tabList.findIndex(v => v.id === tab.tabList[tabIndex].id);
    const excludeIds = tab.tabList.filter((v, i) => i <= startIndex).map(v => v.id);
    tab.closeOtherTab({ excludeIds: excludeIds, newCurrentId: tab.tabList[tabIndex].id });
    closeCallback();
  };
  renderMenu() {
    const { position, tab, tabIndex } = this.props;
    const length = tab.tabList.length;
    const canClose = length > 1;
    const canCloseRight = tabIndex + 1 !== length;
    return (
      <div className="tab-context-menu-layout" style={{ left: position[0], top: position[1] }}>
        <ul>
          <li className={`${!canClose ? "tab-menu-diable" : ""}`} onClick={e => canClose && this.closeCurrentTab(e)}>
            关闭标签页
          </li>
          <li className={`${!canClose ? "tab-menu-diable" : ""}`} onClick={e => canClose && this.closeOtherTab(e)}>
            关闭其他标签页
          </li>
          <li className={`${!canCloseRight ? "tab-menu-diable" : ""}`} onClick={e => canCloseRight && this.closeRightTab(e)}>
            关闭右侧标签页
          </li>
        </ul>
      </div>
    );
  }
  render() {
    const { visibel } = this.props;
    const content = this.renderMenu();
    return ReactDOM.createPortal(visibel ? content : null, this.ele);
  }
}

export default TabContainer;
