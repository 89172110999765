import React, { Component } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import HomeComponent from "./components/Home";
import { Provider } from "mobx-react";
import { LocaleProvider, locales } from "antd";
import { hot } from "react-hot-loader/root";

// import "src/assets/style/main.less";
import "src/assets/style/index.less";
import "./style/index.less";

const LoginView = Loader.loadModuleComponent("login");
const AboutView = Loader.loadModuleComponent("about");
const CertificationView = Loader.loadModuleComponent("certification");
const SystemUpdate = Loader.loadModuleComponent("systemUpdate");

class RenderApp extends React.Component {
  state = {
    isInit: false,
    isUpdate: false
  };
  componentDidMount() {
    Service.other.systemUpdate().then(res => {
      this.setState({ isUpdate: res.isUpdate, isInit: true });
    });
  }
  render() {
    const { isInit, isUpdate } = this.state;
    if (!isInit) {
      return null;
    }

    return isUpdate ? <SystemUpdate /> : <App />;
  }
}

@hot
class App extends Component {
  render() {
    return (
      <LocaleProvider locale={locales.zh_CN}>
        <Provider {...BaseStore}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={LoginView} />
              <Route exact path="/login/:id" component={LoginView} />
              <Route exact path="/logout" render={() => <LogOut />} />
              <Route exact path="/about" render={() => <AboutView />} />
              <Route exact path="/certification" render={() => <CertificationView />} />
              <Route path="/:tabIndex/:module" render={() => <HomeComponent />} />
              <Route path="/" render={() => <HomeComponent isRedirect={true} />} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </LocaleProvider>
    );
  }
}

@withRouter
class LogOut extends React.Component {
  constructor(props) {
    super(props);
    let { history } = props;
    window.ReactHistory = history;
    BaseStore.user.logout({ isRedirect: false, isLogin: false });
  }
  render() {
    return null;
  }
}

export default RenderApp;
