import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { message } from 'antd'
import { withRouter } from 'react-router-dom'

import LayoutView from '../Layout'

const { Loading, NoPage } = Loader

@withRouter
@inject('tab', 'user', 'menu', 'device')
@observer
class HomeComponent extends Component {
  constructor(props) {
    super(props)
    let { user, history, location } = props
    window.ReactHistory = history
    this.state = {
      isInitData: false,
      initError: false,
      isRedirect: false
    }
    if (!user.isLogin) {
      user.logout({ isRedirect: location.pathname !== '/', isLogin: false })
      return
    }
    this.timer = null
    this.computedSystemTimeDef()
    this.startComputedSystemTime()
  }

  startComputedSystemTime() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.computedSystemTimeDef().finally(() => {
        this.startComputedSystemTime()
      })
    }, 2 * 60 * 1000)
  }

  systemDef() {
    let time = Date.now().valueOf()
    return Service.user.getServerTimeStamp().then(res => {
      let def = Date.now().valueOf() - time
      return Date.now().valueOf() - res + def
    })
  }

  computedSystemTimeDef() {
    let { user } = this.props
    let arr = []
    for (let i = 0; i < 2; i++) {
      arr.push(this.systemDef())
    }
    return Promise.all(arr).then(res => {
      let num = res.reduce((c, n) => c + n)
      user.setSystemTimeDef(Math.round(num / 2))
    })
  }
  componentDidMount() {
    let { user } = this.props

    if (!user.isLogin) {
      return
    }
    message.config({
      top: 52,
      maxCount: 5
    })
    this.initialization()
      .then(() => {
        this.goDefaultPage()
        setTimeout(() => {
          this.setState({ isInitData: true })
        }, 10)
      })
      .catch(e => {
        console.error(e)
        this.setState({ initError: true, isInitData: true })
      })
  }

  async initialization() {
    const { user, menu, device } = this.props
    const result = await Service.user.queryUserInfo({
      id: Utils.getCache('userId', 'session')
    })
    const userInfo = result.data
    if (BSConfig.openSocket) {
      SocketEmitter.connect()
    }

    const resultArr = await Promise.all([
      Service.privilege.queryUserPrivileges(userInfo.id),
      Service.operation.queryOperationCenterMenuAndPrivileges(userInfo.operationCenterId), //获取运营中心菜单
      Service.operation.operationCenterInfo(userInfo.operationCenterId), //获取运营中心信息
      Service.user.getSystemInfo(userInfo.id), //获取用户系统信息
      Service.dictionary.queryAll(), //查询字典
      Shared.queryUserDevice()
      // this.Initialization()
    ])

    const [userPrivileges, centerPrivileges, centerInfo, systemInfo, dict, deviceList, ...other] = resultArr

    //TODO 设置用户信息
    user.setUserInfo(userInfo)
    //TODO 设置用户权限
    menu.setAuth(userPrivileges.data.menus, userPrivileges.data.privileges)

    //TODO 设置当前用户所在运营中心支持的菜单
    menu.setCenterMenu(centerPrivileges.data.menus)

    //TODO 设置运营中心信息
    user.setCenterInfo(centerInfo.data)

    //TODO 设置应用信息
    user.setSystemConfig(systemInfo.data)

    Dict.append(dict.data)

    device.setDeviceMap(deviceList.idMap, deviceList.cidMap)

    try {
      const sceneCode = BaseStore.user.appInfo.sceneCode
      const monitorLabel = Dict.map.monitorLabel[sceneCode]
      BaseStore.menu.auth.func.map(v => {
        if (v.name.includes('keyPersonnel')) {
          v.menuName = v.menuName.replace('重点人员', monitorLabel.keyPerson.label)
          v.title && (v.title = v.title.replace('重点人员', monitorLabel.keyPerson.label))
        } else if (v.name.includes('outsider')) {
          v.menuName = v.menuName.replace('外来人员', monitorLabel.outsider.label)
          v.title && (v.title = v.title.replace('外来人员', monitorLabel.outsider.label))
        }
      })
    } catch {
      console.error('未获取到场景id')
    }

    BSConfig.microService.place && (await Shared.queryPlaceDeviceAndPerson())
    Shared.queryOrganizationDevice()
  }

  goDefaultPage() {
    const { menu, tab, location, isRedirect } = this.props
    if (!isRedirect) {
      this.setState({ isRedirect: true })
      return false
    }
    const moduleName = menu.currentMenu
    const moduleInfo = menu.getInfoByName(moduleName)
    let menuName
    if (moduleInfo) {
      if (Array.isArray(moduleInfo.includeNames) && moduleInfo.includeNames.length > 0) {
        menuName = this.computedNameForIncludeNames(moduleInfo)
      } else {
        menuName = moduleName
      }
    } else {
      menuName = this.getMenuName()
    }
    let module = menu.getInfoByName(menuName)
    if (module) {
      console.log('跳转默认', module)
      tab.goPage({
        moduleName: module.name,
        location,
        action: 'replace',
        isUpdate: true
      })
      let item = menu.findCurrentMenu(module.code)
      menu.setCurrentMenu(item.name)
    } else {
      console.error('未匹配菜单！')
      this.setState({ initError: true })
    }
  }

  getMenuName(index = 0) {
    const { menu } = this.props
    let menuName = this.computedNameForIncludeNames(menu.auth.menu[index])
    if (menuName) {
      return menuName
    } else {
      index += 1
      return this.getMenuName(index)
    }
  }

  computedNameForIncludeNames(menuItem) {
    const { menu } = this.props
    let menuName
    let includeNames = menuItem.includeNames
    if (Array.isArray(includeNames) && includeNames.length > 0) {
      for (let i in includeNames) {
        let item = menu.getInfoByName(includeNames[i])
        if (item) {
          if (Array.isArray(item.includeNames) && item.includeNames.length > 0) {
            return this.computedNameForIncludeNames(item)
          } else {
            menuName = includeNames[i]
            break
          }
        }
      }
    } else {
      menuName = menu.userMenuList[0].name
    }
    return menuName
  }

  componentDidUpdate() {
    const { user } = this.props
    if (!user.isLogin) {
      user.logout({ isRedirect: true, isLogin: false })
    }
  }

  componentWillUnmount() {
    if (BSConfig.openSocket) {
      SocketEmitter.disconnect()
    }

    clearTimeout(this.timer)
    this.timer = null
  }

  render() {
    const { isInitData, isRedirect, initError } = this.state
    if (!isInitData) {
      return <Loading />
    }
    if (initError) {
      return <NoPage />
    }

    return <LayoutView isRedirect={isRedirect} />
  }
}
export default HomeComponent
