import React from 'react';
import { Button } from 'antd';
import '../style/broad-notice.less'

const NoticeImg = "/resource/image/Notice.svg"

const IconFont = Loader.loadBaseComponent('IconFont')

const BroadComponent = ({downloadUrl}) => (
  <div className='broad-notice-container'>
    <img src={NoticeImg} />
    <p>
      请转至您的客户端使用智慧广播服务<br></br>
      如未安装,请下载：
    </p>
    <a href={downloadUrl}>
      <Button type='primary'>
        <IconFont type='icon-S_Edit_Download' theme="outlined" />
        客户端下载
      </Button>
    </a>
  </div>
)

export default BroadComponent;